<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="numsidebar">
                <div class="sidebarmain">
                    <a-tabs v-model="active_key" :default-active-key="4" tab-position="left" type="card">
                        <a-tab-pane :key="4" tab="核酸采样统计"></a-tab-pane>
                        <a-tab-pane :key="5" tab="核酸检测检验统计"></a-tab-pane>
                        <a-tab-pane :key="6" tab="核酸检测审核统计"></a-tab-pane>
                        <a-tab-pane :key="1" tab="委托单统计"></a-tab-pane>
                        <a-tab-pane :key="2" tab="样品采集统计"></a-tab-pane>
                        <a-tab-pane :key="3" tab="样品接收统计"></a-tab-pane>
                    </a-tabs>
                </div>
            </div>
            <div class="list-right wrap-container">
                <div v-if="this.active_key === 1">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-date-picker placeholder="请输入开始时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline.starttime"/>
                                <a-date-picker placeholder="请输入结束时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline.endtime"/>
                                <!--                                <a-button @click="searchClick()" type="primary">-->
                                <!--                                    搜索-->
                                <!--                                </a-button>-->
                                <a-button @click="freesearchClick()" type="danger">
                                    清空搜索
                                </a-button>
                                <span style="color:#ff525d">* 开始时间和结束时间相同显示当天数据</span>
                            </a-space>
                        </a-col>
                        <a-col :span="4" style="text-align: right">
                            <!--                            <a-button type="danger">导出表格数据</a-button>-->
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="20">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th>负责人Id</th>
                                    <th>人员姓名</th>
                                    <th>新建委托单数量</th>
                                    <th>归档委托单数量</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,key) in weituotableData" :key="key">
                                    <td>{{item.check_id}}</td>
                                    <td>{{item.check_name}}</td>
                                    <td>{{item.guidang_num}}</td>
                                    <td>{{item.xinjian_num}}</td>
                                </tr>
                                </tbody>
                            </table>
                            <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="this.active_key === 2">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-date-picker placeholder="请输入开始时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline1.starttime"/>
                                <a-date-picker placeholder="请输入结束时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline1.endtime"/>
                                <!--                                <a-button @click="searchClick1()" type="primary">-->
                                <!--                                    搜索-->
                                <!--                                </a-button>-->
                                <a-button @click="freesearchClick()" type="danger">
                                    清空搜索
                                </a-button>
                                <span style="color:#ff525d">* 开始时间和结束时间相同显示当天数据</span>
                            </a-space>
                        </a-col>
                        <a-col :span="4" style="text-align: right">
                            <!--                            <a-button type="danger">导出表格数据</a-button>-->
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="20">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th>人员姓名</th>
                                    <th>指派采集样品任务数量</th>
                                    <th>接受采集任务数量</th>
                                    <th>采集样品数量</th>
                                    <th>提交保管样品数量</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,key) in PickitemtableData" :key="key">
                                    <td>{{item.check_name}}</td>
                                    <td>{{item.zhipai_num}}</td>
                                    <td>{{item.jeishou_num}}</td>
                                    <td>{{item.caiji_num}}</td>
                                    <td>{{item.baoguan_num}}</td>
                                </tr>
                                </tbody>
                            </table>
                            <a-pagination :current="pagecaiji" :pageSize="pagesizecaiji" @change="onChangecaiji" :total="countcaiji" v-if="countcaiji>pagesizecaiji"/>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="this.active_key === 3">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-date-picker placeholder="请输入开始时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline2.starttime"/>
                                <a-date-picker placeholder="请输入结束时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline2.endtime"/>

                                <a-button @click="freesearchClick()" type="danger">
                                    清空搜索
                                </a-button>
                                <span style="color:#ff525d">* 开始时间和结束时间相同显示当天数据</span>
                            </a-space>
                        </a-col>
                        <a-col :span="4" style="text-align: right">
                            <!--                            <a-button type="danger">导出表格数据</a-button>-->
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="20">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th>人员姓名</th>
                                    <th>接收保管样品数量</th>
                                    <th>留样样品数量</th>
                                    <th>退回样品数量</th>
                                    <th>销毁样品数量</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,key) in getitemtableData" :key="key">
                                    <td>{{item.get_name}}</td>
                                    <td>{{item.jeishou_num}}</td>
                                    <td>{{item.liuyang_num}}</td>
                                    <td>{{item.tuihui_num}}</td>
                                    <td>{{item.xiaohui_num}}</td>
                                </tr>
                                </tbody>
                            </table>
                            <a-pagination :current="pagejieshou" :pageSize="pagesizejieshou" @change="onChangejieshou" :total="countjieshou" v-if="countjieshou>pagesizejieshou"/>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="this.active_key === 4">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-date-picker placeholder="请输入开始时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline3.starttime"/>
                                <a-date-picker placeholder="请输入结束时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline3.endtime"/>
                                <a-button @click="freesearchClick()" type="danger">
                                    清空搜索
                                </a-button>
                                <span style="color:#ff525d">* 开始时间和结束时间相同显示当天数据</span>
                            </a-space>
                        </a-col>
                        <a-col :span="4" style="text-align: right">
                            <!--                            <a-button type="danger">导出表格数据</a-button>-->
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="20">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th>采样人</th>
                                    <th>标本类型</th>
                                    <th>检验类型</th>
                                    <th>标本数量</th>
                                    <th>采集数量</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(item,key) in caiyangtableData">
                                    <tr>
                                        <!--                                    <td>{{item.username}}</td>-->
                                        <!--                                    <td>{{item.list}}</td>-->
                                        <!--                                    <td :rowspan="item.row1_len">{{ item.username }}</td>-->
                                        <!--                                    <td :rowspan="item.row2_len">{{ item.title }}</td>-->
                                        <!--                                    <td >{{ item.username }}</td>-->
                                        <!--                                    <td >{{ item.title }}</td>-->

                                        <td v-if="item.kk === 0 && item.kkk === 0" :rowspan="item.row1_len+1">{{
                                            item.username }}
                                        </td>
                                        <td v-if="item.kkk === 0" :rowspan="item.row2_len">{{ item.title }}</td>

                                        <td>{{ item.item_title }}</td>
                                        <td>{{ item.biaoben }}</td>
                                        <!--                                    <td>{{ item }}</td>-->
                                        <td>{{ item.renshu }}</td>
                                        <!--                                    <td>{{item.list.list.title}}</td>-->
                                        <!--                                    <td>{{item.list.list.title}}</td>-->
                                        <!--                                    <td>{{item.list.list.statics.biaoben}}</td>-->
                                        <!--                                    <td>{{item.list.list.statics.renshu}}</td>-->
                                    </tr>
                                    <tr v-if="item.break_ === item.row1_len">
                                        <td colspan="2" style="text-align: right"><b>合计</b></td>
                                        <td><b>{{item.biaoben_}}</b></td>
                                        <td><b>{{item.renshu_}}</b></td>
                                    </tr>
                                </template>
                                <tr>
                                    <td colspan="3" style="text-align: right"><b>总计</b></td>
                                    <td><b>{{ sum4.biaoben }}</b></td>
                                    <td><b>{{ sum4.renshu }}</b></td>
                                </tr>
                                </tbody>

                            </table>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="this.active_key === 5">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-date-picker placeholder="请输入开始时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline4.starttime"/>
                                <a-date-picker placeholder="请输入结束时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline4.endtime"/>
                                <a-button @click="freesearchClick()" type="danger">
                                    清空搜索
                                </a-button>
                                <span style="color:#ff525d">* 开始时间和结束时间相同显示当天数据</span>
                            </a-space>
                        </a-col>
                        <a-col :span="4" style="text-align: right">
                            <!--                            <a-button type="danger">导出表格数据</a-button>-->
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="20">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th>检验人</th>
                                    <th>标本类型</th>
                                    <th>检验类型</th>
                                    <th>标本数量</th>
                                    <th>采集数量</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(item,key) in jianyantableData">
                                    <tr>
                                        <!--                                    <td>{{item.username}}</td>-->
                                        <!--                                    <td>{{item.list}}</td>-->
                                        <!--                                    <td :rowspan="item.row1_len">{{ item.username }}</td>-->
                                        <!--                                    <td :rowspan="item.row2_len">{{ item.title }}</td>-->
                                        <!--                                    <td >{{ item.username }}</td>-->
                                        <!--                                    <td >{{ item.title }}</td>-->

                                        <td v-if="item.kk === 0 && item.kkk === 0" :rowspan="item.row1_len+1">{{
                                            item.username }}
                                        </td>
                                        <td v-if="item.kkk === 0" :rowspan="item.row2_len">{{ item.title }}</td>

                                        <td>{{ item.item_title }}</td>
                                        <td>{{ item.biaoben }}</td>
                                        <!--                                    <td>{{ item }}</td>-->
                                        <td>{{ item.renshu }}</td>
                                        <!--                                    <td>{{item.list.list.title}}</td>-->
                                        <!--                                    <td>{{item.list.list.title}}</td>-->
                                        <!--                                    <td>{{item.list.list.statics.biaoben}}</td>-->
                                        <!--                                    <td>{{item.list.list.statics.renshu}}</td>-->
                                    </tr>
                                    <tr v-if="item.break_ === item.row1_len">
                                        <td colspan="2" style="text-align: right"><b>合计</b></td>
                                        <td><b>{{item.biaoben_}}</b></td>
                                        <td><b>{{item.renshu_}}</b></td>
                                    </tr>
                                </template>
                                <tr>
                                    <td colspan="3" style="text-align: right"><b>总计</b></td>
                                    <td><b>{{ sum5.biaoben }}</b></td>
                                    <td><b>{{ sum5.renshu }}</b></td>
                                </tr>
                                </tbody>
                            </table>

                        </a-col>
                    </a-row>
                </div>
                <div v-if="this.active_key === 6">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-date-picker placeholder="请输入开始时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline5.starttime"/>
                                <a-date-picker placeholder="请输入结束时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" v-model="formInline5.endtime"/>
                                <a-button @click="freesearchClick()" type="danger">
                                    清空搜索
                                </a-button>
                                <span style="color:#ff525d">* 开始时间和结束时间相同显示当天数据</span>
                            </a-space>
                        </a-col>
                        <a-col :span="4" style="text-align: right">
                            <!--                            <a-button type="danger">导出表格数据</a-button>-->
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="20">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th>审核人</th>
                                    <th>标本类型</th>
                                    <th>检验类型</th>
                                    <th>标本数量</th>
                                    <th>采集数量</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(item,key) in shenhetableData">
                                    <tr>
                                        <!--                                    <td>{{item.username}}</td>-->
                                        <!--                                    <td>{{item.list}}</td>-->
                                        <!--                                    <td :rowspan="item.row1_len">{{ item.username }}</td>-->
                                        <!--                                    <td :rowspan="item.row2_len">{{ item.title }}</td>-->
                                        <!--                                    <td >{{ item.username }}</td>-->
                                        <!--                                    <td >{{ item.title }}</td>-->

                                        <td v-if="item.kk === 0 && item.kkk === 0" :rowspan="item.row1_len+1">{{
                                            item.username }}
                                        </td>
                                        <td v-if="item.kkk === 0" :rowspan="item.row2_len">{{ item.title }}</td>

                                        <td>{{ item.item_title }}</td>
                                        <td>{{ item.biaoben }}</td>
                                        <!--                                    <td>{{ item }}</td>-->
                                        <td>{{ item.renshu }}</td>
                                        <!--                                    <td>{{item.list.list.title}}</td>-->
                                        <!--                                    <td>{{item.list.list.title}}</td>-->
                                        <!--                                    <td>{{item.list.list.statics.biaoben}}</td>-->
                                        <!--                                    <td>{{item.list.list.statics.renshu}}</td>-->
                                    </tr>
                                    <tr v-if="item.break_ === item.row1_len">
                                        <td colspan="2" style="text-align: right"><b>合计</b></td>
                                        <td><b>{{item.biaoben_}}</b></td>
                                        <td><b>{{item.renshu_}}</b></td>
                                    </tr>
                                </template>
                                </tbody>
                                <tr>
                                    <td colspan="3" style="text-align: right"><b>总计</b></td>
                                    <td><b>{{ sum6.biaoben }}</b></td>
                                    <td><b>{{ sum6.renshu }}</b></td>
                                </tr>
                            </table>

                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "work",
        components: {},
        data() {
            return {
                sum4: {
                    biaoben: 0,
                    renshu: 0,
                },
                sum5: {
                    biaoben: 0,
                    renshu: 0,
                },
                sum6: {
                    biaoben: 0,
                    renshu: 0,
                },
                page: 1,
                count: 0,
                pagesize: 0,

                pagecaiji: 1,
                countcaiji: 0,
                pagesizecaiji: 0,

                pagejieshou: 1,
                countjieshou: 0,
                pagesizejieshou: 0,


                active_key: 4,
                weituotableData: [],
                PickitemtableData: [],
                getitemtableData: [],
                caiyangtableData: [],
                jianyantableData: [],
                shenhetableData: [],
                formInline: {
                    starttime: '',
                    endtime: '',
                },
                formInline1: {
                    starttime: '',
                    endtime: '',
                },
                formInline2: {
                    starttime: '',
                    endtime: '',
                },
                formInline3: {
                    starttime: '',
                    endtime: '',
                },
                formInline4: {
                    starttime: '',
                    endtime: '',
                },
                formInline5: {
                    starttime: '',
                    endtime: '',
                },
                page_show: false,
                page_show1: false,
                page_show2: false,
                page_show3: false,
                page_show4: false,
                page_show5: false,
            }
        },
        watch: {
            active_key() {
                if (this.page_show) {
                    this.searchClick()
                }
                if (this.page_show1) {
                    this.searchClick1()
                }
                if (this.page_show2) {
                    this.searchClick2()
                }
                if (this.page_show3) {
                    this.searchClick3()
                }
                if (this.page_show4) {
                    this.searchClick4()
                }
                if (this.page_show5) {
                    this.searchClick5()
                }
            },
            formInline: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },
            formInline1: {
                handler() {
                    if (this.page_show1) this.searchClick1()
                },
                deep: true
            },
            formInline2: {
                handler() {
                    if (this.page_show2) this.searchClick2()
                },
                deep: true
            },

            formInline3: {
                handler() {
                    if (this.page_show3) this.searchClick3()
                },
                deep: true
            },
            formInline4: {
                handler() {
                    if (this.page_show4) this.searchClick4()
                },
                deep: true
            },
            formInline5: {
                handler() {
                    if (this.page_show5) this.searchClick5()
                },
                deep: true
            },
            $route: {
                handler() {
                    this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
                    this.pagecaiji = Number(this.$route.query.page ? this.$route.query.page : 1)
                    this.pagejieshou = Number(this.$route.query.page ? this.$route.query.page : 1)
                    this.active_key = Number(this.$route.query.active ? this.$route.query.active : 4)
                    this.formInline.starttime = this.$route.query.starttime || '';
                    this.formInline.endtime = this.$route.query.endtime || '';
                    this.formInline1.starttime = this.$route.query.starttime || '';
                    this.formInline1.endtime = this.$route.query.endtime || '';
                    this.formInline2.starttime = this.$route.query.starttime || '';
                    this.formInline2.endtime = this.$route.query.endtime || '';
                    this.formInline3.starttime = this.$route.query.starttime || '';
                    this.formInline3.endtime = this.$route.query.endtime || '';
                    this.formInline4.starttime = this.$route.query.starttime || '';
                    this.formInline4.endtime = this.$route.query.endtime || '';
                    this.formInline5.starttime = this.$route.query.starttime || '';
                    this.formInline5.endtime = this.$route.query.endtime || '';
                    this.Task_total()
                    this.Pickitem_total()
                    this.Getitem_total()
                    this.Nucleiccaiyang_total()
                    this.Nucleicjiance_total()  //核酸检测检验统计
                    this.Nucleicshenhe_total()  //核酸检测审核统计
                },
                deep: true
            }
        },
        mounted() {
            this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
            this.pagecaiji = Number(this.$route.query.page ? this.$route.query.page : 1)
            this.pagejieshou = Number(this.$route.query.page ? this.$route.query.page : 1)
            this.active_key = Number(this.$route.query.active ? this.$route.query.active : 4)
            this.formInline.starttime = this.$route.query.starttime || '';
            this.formInline.endtime = this.$route.query.endtime || '';
            this.formInline1.starttime = this.$route.query.starttime || '';
            this.formInline1.endtime = this.$route.query.endtime || '';
            this.formInline2.starttime = this.$route.query.starttime || '';
            this.formInline2.endtime = this.$route.query.endtime || '';
            this.formInline3.starttime = this.$route.query.starttime || '';
            this.formInline3.endtime = this.$route.query.endtime || '';
            this.formInline4.starttime = this.$route.query.starttime || '';
            this.formInline4.endtime = this.$route.query.endtime || '';
            this.formInline5.starttime = this.$route.query.starttime || '';
            this.formInline5.endtime = this.$route.query.endtime || '';

            this.Task_total()   //委托单统计
            this.Pickitem_total()  //样品采集统计
            this.Getitem_total() //样品接受统计
            this.Nucleiccaiyang_total()  //核酸采样统计
            this.Nucleicjiance_total()  //核酸检测检验统计
            this.Nucleicshenhe_total()  //核酸检测审核统计

            setTimeout(() => {
                this.page_show = true
                this.page_show1 = true
                this.page_show2 = true
                this.page_show3 = true
                this.page_show4 = true
                this.page_show5 = true
            }, 1)

        },
        methods: {
            // 搜索
            searchClick() {
                this.$router.push({
                    query: {
                        active: this.active_key,
                        starttime: this.formInline.starttime,
                        endtime: this.formInline.endtime,
                        t: new Date() / 1
                    }
                })
            },
            searchClick1() {
                console.log(this.formInline1)
                this.$router.push({
                    query: {
                        active: this.active_key,
                        starttime: this.formInline1.starttime,
                        endtime: this.formInline1.endtime,
                        t: new Date() / 1
                    }
                })
            },
            searchClick2() {
                this.$router.push({
                    query: {
                        active: this.active_key,
                        starttime: this.formInline2.starttime,
                        endtime: this.formInline2.endtime,
                        t: new Date() / 1
                    }
                })
            },

            searchClick3() {
                this.$router.push({
                    query: {
                        active: this.active_key,
                        starttime: this.formInline3.starttime,
                        endtime: this.formInline3.endtime,
                        t: new Date() / 1
                    }
                })
            },

            searchClick4() {
                this.$router.push({
                    query: {
                        active: this.active_key,
                        starttime: this.formInline4.starttime,
                        endtime: this.formInline4.endtime,
                        t: new Date() / 1
                    }
                })
            },

            searchClick5() {
                this.$router.push({
                    query: {
                        active: this.active_key,
                        starttime: this.formInline5.starttime,
                        endtime: this.formInline5.endtime,
                        t: new Date() / 1
                    }
                })
            },
            freesearchClick() {
                this.formInline = {
                    starttime: '',
                    endtime: '',
                }
                this.formInline1 = {
                    starttime: '',
                    endtime: '',
                }
                this.formInline2 = {
                    starttime: '',
                    endtime: '',
                }
                this.formInline3 = {
                    starttime: '',
                    endtime: '',
                }
                this.formInline4 = {
                    starttime: '',
                    endtime: '',
                }
                this.formInline5 = {
                    starttime: '',
                    endtime: '',
                }
                this.page = 1
                this.pagecaiji = 1
                this.pagejieshou = 1
                this.searchClick()
                this.searchClick1()
                this.searchClick2()
                this.searchClick3()
                this.searchClick4()
                this.searchClick5()
            },


            Task_total() {
                this.$sa0.post({
                    url: this.$api('Task_total'),
                    data: {
                        page: this.page,
                        starttime: this.formInline.starttime,
                        endtime: this.formInline.endtime,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.weituotableData = response.data.return.list;
                            this.count = Number(response.data.return.count);//分页
                            this.pagesize = Number(response.data.return.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 核酸采样统计
            Nucleiccaiyang_total() {
                this.$sa0.post({
                    url: this.$api('Nucleiccaiyang_total'),
                    data: {
                        starttime: this.formInline3.starttime,
                        endtime: this.formInline3.endtime,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.sum4.biaoben = 0
                            this.sum4.renshu = 0
                            let ll = response.data.list.map((item) => {
                                let length_ = 0
                                item.list.map((i) => {
                                    let len_ = i.list.length;
                                    length_ += len_;
                                    i.len_ = len_;
                                    return i;
                                })
                                item.len_ = length_;
                                return item;
                            });

                            let list = [];
                            ll.map((item, k) => {
                                let break_ = 0
                                let biaoben_ = 0
                                let renshu_ = 0
                                item.list.map((i, kk) => {

                                    i.list.map((ii, kkk) => {
                                        break_++
                                        biaoben_ += ii.statics.biaoben
                                        renshu_ += ii.statics.renshu
                                        list.push({
                                            k: k,
                                            kk: kk,
                                            kkk: kkk,
                                            break_: break_,
                                            biaoben_: biaoben_,
                                            renshu_: renshu_,
                                            create_id: item.create_id,
                                            username: item.username,
                                            row1_len: item.len_,
                                            row2_len: i.len_,
                                            title: i.title,
                                            item_title: ii.title,
                                            biaoben: ii.statics.biaoben,
                                            renshu: ii.statics.renshu,
                                        })
                                        this.sum4.biaoben += ii.statics.biaoben
                                        this.sum4.renshu += ii.statics.renshu
                                    })
                                })

                            });
                            this.caiyangtableData = list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 核酸检测检验统计
            Nucleicjiance_total() {
                this.$sa0.post({
                    url: this.$api('Nucleicjiance_total'),
                    data: {
                        starttime: this.formInline4.starttime,
                        endtime: this.formInline4.endtime,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.sum5.biaoben = 0
                            this.sum5.renshu = 0
                            let ll = response.data.list.map((item) => {
                                let length_ = 0
                                item.list.map((i) => {
                                    let len_ = i.list.length;
                                    length_ += len_;
                                    i.len_ = len_;
                                    return i;
                                })
                                item.len_ = length_;
                                return item;
                            });
                            let list = [];
                            ll.map((item, k) => {
                                let break_ = 0
                                let biaoben_ = 0
                                let renshu_ = 0
                                item.list.map((i, kk) => {
                                    i.list.map((ii, kkk) => {
                                        break_++
                                        biaoben_ += ii.statics.biaoben
                                        renshu_ += ii.statics.renshu
                                        list.push({
                                            k: k,
                                            kk: kk,
                                            kkk: kkk,
                                            break_: break_,
                                            biaoben_: biaoben_,
                                            renshu_: renshu_,
                                            create_id: item.create_id,
                                            username: item.username,
                                            row1_len: item.len_,
                                            row2_len: i.len_,
                                            title: i.title,
                                            item_title: ii.title,
                                            biaoben: ii.statics.biaoben,
                                            renshu: ii.statics.renshu,
                                        })
                                        console.log('biaoben', ii.statics.biaoben)
                                        console.log('renshu', ii.statics.renshu)
                                        this.sum5.biaoben += ii.statics.biaoben
                                        this.sum5.renshu += ii.statics.renshu
                                    })
                                })
                            });
                            this.jianyantableData = list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 核酸检测检验统计
            Nucleicshenhe_total() {
                this.$sa0.post({
                    url: this.$api('Nucleicshenhe_total'),
                    data: {
                        starttime: this.formInline5.starttime,
                        endtime: this.formInline5.endtime,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.sum6.biaoben = 0
                            this.sum6.renshu = 0
                            let ll = response.data.list.map((item) => {
                                let length_ = 0
                                item.list.map((i) => {
                                    let len_ = i.list.length;
                                    length_ += len_;
                                    i.len_ = len_;
                                    return i;
                                })
                                item.len_ = length_;
                                return item;
                            });

                            let list = [];
                            ll.map((item, k) => {
                                let break_ = 0
                                let biaoben_ = 0
                                let renshu_ = 0
                                item.list.map((i, kk) => {

                                    i.list.map((ii, kkk) => {
                                        break_++
                                        biaoben_ += ii.statics.biaoben
                                        renshu_ += ii.statics.renshu
                                        list.push({
                                            k: k,
                                            kk: kk,
                                            kkk: kkk,
                                            break_: break_,
                                            biaoben_: biaoben_,
                                            renshu_: renshu_,
                                            create_id: item.create_id,
                                            username: item.username,
                                            row1_len: item.len_,
                                            row2_len: i.len_,
                                            title: i.title,
                                            item_title: ii.title,
                                            biaoben: ii.statics.biaoben,
                                            renshu: ii.statics.renshu,
                                        })
                                        this.sum6.biaoben += ii.statics.biaoben
                                        this.sum6.renshu += ii.statics.renshu
                                    })
                                })
                            });
                            this.shenhetableData = list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            Pickitem_total() {
                this.$sa0.post({
                    url: this.$api('Pickitem_total'),
                    data: {
                        page: this.pagecaiji,
                        starttime: this.formInline1.starttime,
                        endtime: this.formInline1.endtime,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.PickitemtableData = response.data.return.list;
                            this.countcaiji = Number(response.data.return.count);//分页
                            this.pagesizecaiji = Number(response.data.return.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Getitem_total() {
                this.$sa0.post({
                    url: this.$api('Getitem_total'),
                    data: {
                        page: this.pagejieshou,
                        starttime: this.formInline2.starttime,
                        endtime: this.formInline2.endtime,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.getitemtableData = response.data.return.list;
                            this.countjieshou = Number(response.data.return.count);//分页
                            this.pagesizejieshou = Number(response.data.return.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page
                    }
                })
            },

            // 分页
            onChangecaiji(pagecaiji) {
                this.$router.push({
                    query: {
                        pagecaiji: pagecaiji
                    }
                })
            },

            // 分页
            onChangejieshou(pagejieshou) {
                this.$router.push({
                    query: {
                        pagejieshou: pagejieshou
                    }
                })
            },

        }

    }
</script>

<style>
    .list-left{
        background: #f9f9f9;
        min-height: 850px;
        border: 1px solid #eeeeee;
        border-right: none;
        height: 100%;
        padding: 20px 0 0 10px
        }

    .list-right{
        padding-left: 20px;

        }

    .ant-tabs .ant-tabs-left-bar{
        float: right !important;
        margin-right: -1px;
        margin-bottom: 0;
        border-right: 1px solid #e8e8e8;
        width: 100% !important;
        }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active{
        margin-right: -1px;
        padding-right: 40px !important;
        font-weight: bold;
        }

    .addbutton{
        width: 100%;
        text-align: center;
        margin-left: -5px;
        }

    .wraper{
        width: 100%;
        height: 100%;
        display: flex;
        }

    .wrap-mainer{
        flex: 1;
        display: flex;
        }

    .numsidebar{
        width: 200px;
        display: flex;
        flex-direction: column;
        background: #f9f9f9;
        padding: 10px;
        padding-right: 0;
        height: 800px;
        }

    .sidebarmain{
        flex: 1;
        overflow-y: auto
        }

    .wrap-container{
        flex: 1;
        overflow-y: auto
        }
</style>
